@import "../colors";

.contact-page {

	address, dd {
		@extend .fc-darker-gray, .fw-300;
	}

	.contact-links {
		@extend .fw-300;

		li {
			@extend .mb-2;
		}
	}

	.map {
		position: relative;
		padding-bottom: 75%; // This is the aspect ratio
		height: 0;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}
