@import "variables";

@mixin retina-background-image($base-name, $alpha: null) {

	$rgba: if($alpha, rgba(0, 0, 0, $alpha), null);

	$path: '/img/background/';
	$ext: '.jpeg';
	$breakpoints: (
			'xs': ('0', 768px),
			'sm': (769px, 992px),
			'md': (993px, 1200px),
			'lg': (1201px, 'inf')
	);

	@each $size, $range in $breakpoints {
		$start: nth($range, 1);
		$end: nth($range, 2);
		$lower-bound: if($start != '0', '(min-device-width: ' + $start +') ', '');
		$upper-bound: if($end != 'inf', '(max-device-width: ' + $end +') ', '');
		$and: if($lower-bound != '' and $upper-bound != '', 'and ', '');
		$query: $lower-bound + $and + $upper-bound;
		$filter: if($rgba, linear-gradient($rgba, $rgba) + ', ', null);

		@media only screen and #{$query} {
			background-image: $filter url($path + $base-name + '-' + $size + $ext);
		}

		@media only screen and #{$query} and (-webkit-min-device-pixel-ratio: 2),
		only screen and #{$query} and (min-resolution: 2dppx) {
			background-image: $filter url($path + $base-name + '-' + $size + '@2x' + $ext);
		}
	}
}
