.btn {
	cursor: pointer;
}

input[type="text"],
input[type="email"],
input[type="date"] {
	// https://github.com/twbs/bootstrap/issues/18842
	// https://github.com/twbs/bootstrap/issues/23307
	height: 2.375rem;
}
