@import "colors";
@import "bootstrap";
@import "breakpoints";

@mixin headings {
	h1, h2, h3, h4, h5, h6 {
		@content;
	}
}

@font-face {
	font-family: 'Handel Gothic';
	src: url('/font/handel-gothic-light.eot');
	src: url('/font/handel-gothic-light.eot?#iefix') format('embedded-opentype'),
	url('/font/handel-gothic-light.woff2') format('woff2'),
	url('/font/handel-gothic-light.woff') format('woff'),
	url('/font/handel-gothic-light.ttf') format('truetype'),
	url('/font/handel-gothic-light.svg#wf') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Handel Gothic';
	src: url('/font/handel-gothic-bold.eot');
	src: url('/font/handel-gothic-bold.eot?#iefix') format('embedded-opentype'),
	url('/font/handel-gothic-bold.woff2') format('woff2'),
	url('/font/handel-gothic-bold.woff') format('woff'),
	url('/font/handel-gothic-bold.ttf') format('truetype'),
	url('/font/handel-gothic-bold.svg#wf') format('svg');
	font-weight: bold;
	font-style: normal;
}

%ff-handel-gothic {
	font-family: 'Handel Gothic', sans-serif;
}

%ff-open-sans {
	font-family: 'Open Sans', sans-serif;
}

%ff-roboto {
	font-family: 'Roboto', sans-serif;
}

@each $fontColor, $color in $brand-colors {
	.fc-#{$fontColor} {
		color: $color !important;
	}
}

@each $fontWeight in 100, 200, 300, 400, 500, 600, 700, 800, 900 {
	.fw-#{$fontWeight} {
		font-weight: $fontWeight;
	}
}

@each $fontSize in 90, 95, 100, 110, 120 {
	.fs-#{$fontSize} {
		font-size: percentage($fontSize * 0.01);
	}
}

@each $lineHeight in 1.0, 1.2, 1.5, 2.0 {
	.lh-#{$lineHeight * 10} {
		line-height: #{$lineHeight}em;
	}
}

@each $letterSpacing in 0.0125, 0.025, 0.05 {
	.ls-#{str_slice(quote($letterSpacing), 3)} {
		letter-spacing: #{$letterSpacing}em;
	}
}

html {
	font-size: 12px;
}

@include media-breakpoint-orientation-md-up() {
	html {
		font-size: 14px;
	}
}

@include media-breakpoint-up(lg) {
	html {
		font-size: 16px;
	}
}

body {
	@extend %ff-open-sans, .ls-0125;

	@include brand-color(black);
}

@include headings {
	@extend %ff-roboto, .fw-500, .ls-025;

	@include brand-color(darkest-gray);
}

.dark-headings {
	@include headings {
		@include brand-color(black);
	}
}

.lead {
	@extend %ff-roboto, .fc-near-black, .fw-300, .ls-025;
}

.dark-links {
	a {
		@extend .fc-gray;

		&:hover {
			@extend .fc-dark-gray;
		}
	}
}

.darker-links {
	a {
		@extend .fc-darker-gray;

		&:hover {
			@extend .fc-dark-gray;
		}
	}
}

.section-list {
	@extend .fw-300, .pl-5;

	> li {
		@extend .pb-2;
	}
}

.no-bullet {
	list-style-type: none;
}

.label-legend {
	font-size: 1.25rem;
}

.styled-link {
	color: inherit;
	text-decoration: underline;
}

.unstyled-link {
	color: inherit;

	&:hover {
		text-decoration: none;
	}
}

.copyright, .policy {
	@extend .fw-300, .small;
}
