@import "../colors";
@import "../fonts";
@import "../images";

.intro-class-enroll-page {
	height: 100%;

	> body {
		position: relative;
		min-height: 100%;
		@include retina-background-image('spacex-landing-20160408');
		background: {
			position: 40% center;
			size: cover;
			repeat: no-repeat;
		}

		@include media-breakpoint-down(sm) {
			background-position: 45% center;
		}

		@media (min-width: 1400px) {
			background-position: left center;
		}

		// Start a stacking context so positioned descendants can can use
		// a negative z-index, but still appear over the background image
		z-index: 0;
	}
}
