@import "colors";

@each $color in primary, secondary, ternary {
	.btn {
		&.btn-outline-brand-#{$color} {
			@include button-outline-variant(get-brand-color($color));

			&.active {
				@extend .fw-600, .brand-color-#{$color};

				background-color: transparent;
			}
		}
	}
}

.btn.btn-brand {
	@include brand-color(white);
	@include brand-bg-color(primary);
	@include brand-border-color(primary);

	&:visited {
		@include brand-color(white);
	}
}

@media (hover: hover) {
	.btn.btn-brand:hover {
		@include brand-color(white);
		@include brand-bg-color(darker-gray);
		@include brand-border-color(secondary);
	}
}

.btn-icon {
	background-color: transparent;
}
