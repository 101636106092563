@import "variables";

@function get-brand-color($color) {
	@return map-get($brand-colors, $color);
}

@mixin brand-color($color) {
	color: get-brand-color($color);
}

@mixin brand-bg-color($color) {
	background-color: get-brand-color($color);
}

@mixin brand-border-color($color) {
	border-color: get-brand-color($color);
}

@each $color-name, $color in $brand-colors {

	.brand-color-#{$color-name} {
		color: $color;
	}

	.brand-bg-color-#{$color-name} {
		background-color: $color;
	}

	.brand-border-color-#{$color-name} {
		border-color: $color;
	}
}
