@import "bootstrap";
@import "positioning";
@import "breakpoints";
@import "colors";
@import "fonts";


.brand {
	// Position the brand div to serve as the relative location of the hanging brand-tagline div
	position: relative;
	// Inline block the brand div so the brand tagline ends at the right edge of its contents
	display: inline-block;
}

.brand-link {
	@extend .d-flex, .align-items-center, .py-2;
	position: relative;

	&:hover {
		text-decoration: none;
	}
}

.brand-logo {
	height: 2.75rem;
}

.brand-logotype {
	@extend %ff-handel-gothic;
	@include brand-color(darker-gray);
	$logotype-font-size: 1.5rem;
	font-size: $logotype-font-size;
	line-height: $logotype-font-size;
	white-space: nowrap;
}

$brand-tagline-height: 1.875rem;

.brand-tagline {
	@extend .d-flex, .align-items-end, .brand-bg-color-primary;

	// Base the hanging tagline div off the navbar-brand div so their right sides will align.
	@include absolute(0, 0, -$brand-tagline-height, 0);

	border-bottom-right-radius: 3px;
	z-index: -1;

	// Extend the navbar-tagline background color from the tagline all the way to left of the viewport
	&::before {
		content: "";
		@include absolute(0, 0, 0, -1000%);
		border-bottom-right-radius: inherit;
		background-color: inherit;
		z-index: inherit;
	}

	> span {
		@extend .align-middle, .fc-white, .ls-05, .fw-600;
		display: block;
		padding-left: 3px; // Account for the logo image padding
		font: {
			size: 1rem;
			style: italic;
		}
		line-height: $brand-tagline-height;
		text-decoration: none;
	}
}

.navbar {

	.navbar-collapse {

		.navbar-nav {
			@extend .py-3, .py-lg-0;

			.nav-link, .btn {
				@extend .fw-600;
			}

			.nav-item.active > .nav-link {
				@extend .brand-bg-color-lightest-gray;
			}

			@include media-breakpoint-down(sm) {
				.nav-link {
					padding: map_get($spacers, 3);
				}
			}
		}

		.navbar-actions {
			@extend .d-flex, .justify-content-end, .pb-3, .pb-lg-0;

			> .btn {
				@extend .ml-3;
			}
		}
	}
}
