@import "colors";

.social-icon {
	@include brand-color(white);
	width: 2em;
	height: 2em;
	border-radius: 1em;
	font-size: 1em;
	text-align: center;
	margin-right: .5em;
	padding-top: .5em;
	transition: all 0.2s ease-in-out;

	&:hover {
		opacity: .7;
	}
}

.fa-facebook {
	background: #3B5998;
}

.fa-linkedin {
	background: #007BB6;
}

.fa-twitter {
	background: #00ACED;
}

.fa-google-plus {
	background: #DD4B39;
}
