@import "variables";
@import "bootstrap";
@import "breakpoints";
@import "colors";
@import "fonts";
@import "positioning";
@import "sizing";
@import "buttons";
@import "images";
@import "nav";
@import "covers";
@import "sections";
@import "forms";
@import "social";
@import "pages/contact";
@import "pages/landing";

html {
	min-height: 100%;
	@include brand-bg-color(footer); // For when the viewport is higher than the footer content
}

body {
	min-width: 295px; // Prevent excessive clipping and overflow when viewport is resized exceptionally small
	@include brand-bg-color(white);
}
