@import "bootstrap";
@import "colors";
@import "fonts";
@import "positioning";
@import "images";

.cover-header, .cover-text {
	@include brand-color(white);
	letter-spacing: 0.04em;
}

.cover-header {
	text-shadow: 0 1px 3px $half-fade-rgba;
	font-weight: 500;
}

.cover {
	position: relative;
	border-top: 3.75rem solid $gray-100;
	height: 60vh;
	min-height: 26rem;
	background: {
		position: center center;
		size: cover;
		repeat: no-repeat;
	}

	&.jumbotron {
		@extend .mb-0;
	}

	> .cover-body {
		text-align: center;

		> .cover-header {
			@extend .mb-0;
		}

		> .cover-text {
			@extend .mt-3;
			font-style: italic;
		}
	}

	&.index-page {
		@extend .justify-content-center, .align-items-start;
		height: 70vh;
		@include retina-background-image('curiosity-self-portrait');
		background-position: center 20%;

		.cover-body {
			@extend .mt-4;
		}

		@media (max-width: 500px) and (orientation: portrait) {
			background: {
				position: 80% top;
				size: 160vw;
			}
		}

		@media (min-width: 501px) and (max-width: 768px) and (orientation: portrait) {
			background: {
				position: 80% 20%;
				size: 160vw;
			}
		}

		@media (max-width: 768px) and (orientation: landscape) {
			background: {
				position: right 22.5%;
				size: 125vw;
			}
		}

		@media (min-width: 1200px) {
			background-position: center 27.5%;
		}

		.cover-header {
			// Set the positioning parent of the typed header text so it will overlap the
			// invisible typed placeholder text
			position: relative;
			text-align: left;
			line-height: 1.4em;
		}

		.typed-placeholder {
			// Statically position the longest typed header text to reserve room for
			// the typed header text (keeps the Enroll button from jumping up and down
			// as the text is typed in)
			visibility: hidden;
		}

		.typed-link {
			// Overlap the hidden typed placeholder text
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			color: inherit;
			text-decoration: none;

			&:hover {
				color: inherit;
				text-decoration: none;
			}
		}

		.typed-header {
			// Set the positioning parent for the typed cursor
			position: relative;
		}

		#technologies {
			> u {
				text-decoration: none;
				border-bottom: .1em solid get-brand-color(secondary);
			}
		}

		.typed-cursor {
			@extend .fw-300, .fs-120;
			// Pull the larger font-sized typed cursor out of normal flow so it doesn't goof
			// with the line height of the rest of the typed header text (keeps the line heights
			// from jumping around)
			@include absolute($right: -0.3em);
			opacity: 1;
			animation: blink 0.7s infinite;
		}

		.cover-text {
			@extend .d-flex, .mt-3, .mt-md-4;
			justify-content: flex-end;
			@include media-breakpoint-up(md) {
				justify-content: flex-start;
			}
			font-style: normal;
		}
	}

	&.attending-page {
		@include retina-background-image('joint-european-torus', 0.4);
	}

	&.curriculum-page {
		@include retina-background-image('laser-cutter', 0.3);
		background-position: 65% bottom;
	}

	&.contact-page {
		@include retina-background-image('alma-radio-telescope', 0.3);
		background-position: center 80%;
	}

	&.contributing-page {
		@include retina-background-image('ucsb-rezqu-quantum-microprocessor', 0.3);
	}

	&.enroll-page {
		@include retina-background-image('lhc-cms-experiment', 0.4);
	}

	&.error-page {
		@include retina-background-image('jpl-hall-thruster', 0.25);
		background-position: center 30%;
	}

	&.scheduling-page {
		@include retina-background-image('jila-atomic-clock', 0.3);
	}

	&.thank-you-page {
		@include retina-background-image('spacex-falcon-launch');
		background-position: center 85%;
	}

	&.values-page {
		@include retina-background-image('lro-earthrise', 0.1);
		background-position: center 28%;
	}
}

%btn-cover-info {
	line-height: 1em;
}

.btn-cover-info {
	@extend %btn-cover-info;
}

.btn-cover-info-absolute {
	@include absolute($bottom: 0, $right: 0);
}

.btn-cover-info-close {
	@extend %btn-cover-info;
	@include absolute($top: $modal-inner-padding, $right: $modal-inner-padding);
}

.btn-cover-inverse {
	.fa {
		@extend .brand-color-white;
	}
}

.img-cover-info {
	@extend .img-fluid;
	object-fit: contain;
}

@keyframes blink {
	0% { opacity: 1; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}
