@import "colors";
@import "fonts";
@import "sections/pricing";

main, section, footer {
	@extend .py-5;
}

main, section {
	@extend .brand-bg-color-white;
}

.section-near-white {
	@extend .brand-bg-color-near-white;
}

.section-light-gray {
	@extend .fc-darker-gray;
	@extend .brand-bg-color-lighter-gray;
}

.section-bottom-border {
	border-bottom: 1.5rem solid get-brand-color(near-white);
}

.inset-shadow {
	box-shadow: inset 0 7px 5px -5px get-brand-color(darker-gray), inset 0 -7px 7px -7px #BBB;
}

.brand-bottom-border {
	border-bottom: 4px solid get-brand-color(primary);
}

.brand-footer {
	@extend .fc-off-white, .brand-bg-color-footer, .pb-5;

	@include headings {
		@extend .fc-off-white;
	}

	border-top: 3px solid get-brand-color(secondary);

	.footer-logo {
		height: 50px;
		filter: brightness(70%);
	}

	.nav-link {
		@extend .py-2, .px-0;

		&:hover {
			text-decoration: underline;
		}
	}

	.copyright, .policy {
		@extend .fc-darker-gray;
	}
}

.anchor {
	// http://nicolasgallagher.com/jump-links-and-viewport-positioning/demo/#method-D
	$navbar-spacing: 4rem;
	border-top: $navbar-spacing solid transparent;
	margin-top: -$navbar-spacing;
	background-clip: padding-box;
}

.transparent {
	background-color: transparent;
}

@mixin frosted($bg-color: white) {
	background-color: rgba($bg-color, 0.95);
	$blur-amount: 20px;
	@supports (-webkit-backdrop-filter: blur($blur-amount)) {
		background-color: rgba($bg-color, 0.75);
		-webkit-backdrop-filter: blur($blur-amount);
	}
}

.frosted {
	@include frosted();
}

.frosted-dark {
	@include frosted(get-brand-color(dark-gray));
}
